import {SelectTableModalComponent} from './../select-table-modal/select-table-modal.component';
import {environment} from 'src/environments/environment';
import { Component, Input, EventEmitter } from '@angular/core';
import Order from 'src/smoothr-web-app-core/models/Order';
import {ModalController} from '@ionic/angular';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { Output } from '@angular/core';

@Component({
	selector: 'app-chip-table',
	templateUrl: './chip-table.component.html',
	styleUrls: ['./chip-table.component.scss']
})
export class ChipTableComponent {
	@Input() order: Order;
	@Input() venue: Venue;
	@Output() selectTableModal = new EventEmitter<void>();
	environment = environment;
	constructor(private modalCtrl: ModalController) {
	}
	async openSelectTable() {
		this.selectTableModal.emit();
	}
}
