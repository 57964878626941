import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SignUpPage} from '../sign-up/sign-up.page';
import validator from 'validator';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrderPage} from '../order/order.page';
import {AlertController, ModalController} from '@ionic/angular';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {environment} from '../../../environments/environment';
import {delay} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AccountPage} from '../account/account.page';
import {Subscription} from 'rxjs';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {AuthStrategy} from '../../../smoothr-web-app-core/models/AuthStrategy';
import {EmailAction} from '../../enums/EmailAction';
import { AppRestorePasswordModalComponent } from 'src/app/components/app-restore-password-modal/app-restore-password-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.page.html',
	styleUrls: ['sign-in.page.scss']
})
export class SignInPage implements OnInit, OnDestroy {
	static url = 'sign-in';
	loading = false;
	showPassword = false;
	signInForm: FormGroup;
	email = 'email'
	password = 'password'
	private readonly subscription: Subscription;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private alertCtrl: AlertController,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private fb: FormBuilder
	) {
		this.subscription = repository.customerAuth.subscribe(customerAuth => {
			if (customerAuth && customerAuth.customer && !customerAuth.customer.isAnonymous) {
				AccountPage.navigate(router);
				this.subscription.unsubscribe();
			}
		});
	}


	static async navigate(router: Router, redirectToOrder: boolean = false) {
		await router.navigateByUrl(SignInPage.url, {
			state: {
				redirectToOrder
			}
		});
	}

	static async navigateWithParams(router: Router, queryParams: Params) {
		await router.navigate([SignInPage.url], {
			queryParams,
			replaceUrl: true
		});
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(async params => {
			if (params.id && params.auth) {
				this.processOneTimeLoginToken(params.id, params.auth);
				return;
			}
			if (params.mode === EmailAction.RESET_PASSWORD && params.token) {
				await this.showNewPasswordDialog(params.token);
			}
		});

		this.createSignInForm();
	}


	createSignInForm() {
		const formControlNames: any = {}

		formControlNames[this.email] = ['', Validators.required];
		formControlNames[this.password] = ['', Validators.required];

		this.signInForm = this.fb.group(formControlNames);
	}

	getControlValue(controlName?: string) {
		if(controlName) {
			return this.signInForm?.get(controlName)?.value;
		}
		return '';
	}


	async processOneTimeLoginToken(id: string, auth: string) {
		this.loading = true;
		try {
			const res = (await Api.oneTimeLogin(id, auth)).data;
			this.repository.customerAuth.emit(res);
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	signUp() {
		SignUpPage.navigate(this.router);
	}



	async signIn() {
		if(this.signInForm.valid) {
			this.loading = true;
			try {
				const response = (await Api.signInWithCredentials(this.getControlValue('email'), this.getControlValue('password'))).data;
				this.repository.customerAuth.emit(response);
				if (history.state.redirectToOrder) {
					await OrderPage.navigate(this.router);
				} else if (this.repository._order === null) {
					await HomePage.navigate(this.router);
				} else {
					await MenuPage.navigate(this.router);
				}
				this.snackbarCtrl.open(this.translate.instant('sign_in.success'), null, {
					duration: 5000
				});
			} catch (e) {
				this.snackbarCtrl.open(this.translate.instant('sign_in.error'), null, {
					duration: 5000
				});
				console.error(e);
			}
			this.loading = false;
		} else {
			this.signInForm.markAllAsTouched()
			this.snackbarCtrl.open(this.translate.instant('sign_in.form_not_valid'), null, {
				duration: 5000
			});
		}
	}

	async showResetPasswordDialog() {
		const response = await AppRestorePasswordModalComponent.show(this.modalCtrl);
		if (response) {
			try {
				await Api.forgotPassword(response.data);
				this.snackbarCtrl.open(this.translate.instant('reset_password.success'));
			} catch (err) {
				this.snackbarCtrl.open(this.translate.instant('reset_password.error'));
				console.log(err);
			}
		}
	}
	async showNewPasswordDialog(resetToken: string) {
		const passwordResetAlert = await this.alertCtrl.create({
			header: this.translate.instant('new_password.header'),
			message: this.translate.instant('new_password.msg'),
			inputs: [
				{
					name: 'password1',
					placeholder: this.translate.instant('new_password.password'),
					type: 'password'
				},
				{
					name: 'password2',
					placeholder: this.translate.instant('new_password.re_password'),
					type: 'password'
				}
			],
			buttons: [
				{
					text: this.translate.instant('new_password.cancel_btn'),
					role: 'cancel',
					cssClass: 'secondary'
				},
				{
					text: this.translate.instant('new_password.ok_btn'),
					handler: async result => {
						if (result.password1 !== result.password2) {
							this.snackbarCtrl.open(this.translate.instant('new_password.password_match_error'), null, {
								duration: 2000
							});
							await delay(2000);
							await this.showNewPasswordDialog(resetToken);
							return;
						}
						if (!ValidationUtils.validatePassword(result.password1)) {
							this.snackbarCtrl.open(this.translate.instant('new_password.password_error'), null, {
								duration: 6000
							});
							await delay(2000);
							await this.showNewPasswordDialog(resetToken);
							return;
						}
						try {
							await Api.resetPassword(resetToken, result.password1);
							await SignInPage.navigate(this.router);
							this.snackbarCtrl.open(this.translate.instant('new_password.success'), null, {
								duration: 2000
							});
						} catch (e) {
							this.snackbarCtrl.open(this.translate.instant('new_password.error') + e, null, {
								duration: 2000
							});
						}
					}
				}
			]
		});
		await passwordResetAlert.present();
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}

	async signInWithGoogle() {
		if (!this.loading) {
			this.loading = true;
			try {
				const result = (await Api.signIn(AuthStrategy.GOOGLE)).data;
				window.location.href = result.url;
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signInWithFacebook() {
		if (!this.loading) {
			this.loading = true;
			try {
				const result = (await Api.signIn(AuthStrategy.FACEBOOK)).data;
				window.location.href = result.url;
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signInWithApple() {
		if (!this.loading) {
			this.loading = true;
			try {
				const result = (await Api.signIn(AuthStrategy.APPLE)).data;
				window.location.href = result.url;
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}
	isValid(controlName?: string): boolean {
		if(controlName) {
			return this.signInForm.get(controlName)?.hasError('required') && this.signInForm.get(controlName)?.touched;
		}

		return this.signInForm.invalid;
	}
}
