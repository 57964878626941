import {AfterViewInit, Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {environment} from 'src/environments/environment';
import Article from 'src/smoothr-web-app-core/models/Article';
import Compound from 'src/smoothr-web-app-core/models/Compound';
import Localization from '../../../smoothr-web-app-core/models/Localization';

@Component({
	selector: 'app-short-info-modal',
	templateUrl: './short-info-modal.component.html',
	styleUrls: ['short-info-modal.component.scss']
})
export class ShortInfoModalComponent implements AfterViewInit {
	article: Article;
	compound: any;
	environment = environment;

	constructor(private modalCtrl: ModalController) {}
	ngAfterViewInit(): void {
		console.log(Object.values(this.compound.allergens).find(it => it === true));
		console.log(Object.values(this.compound.additives).find(it => it === true));
	}
	static async show(modalCtrl: ModalController, article: Article) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: ShortInfoModalComponent,
			componentProps: {
				article,
				compound: article?.compound
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
	isAllergens() {
		return Object.values(this.compound?.allergens).find(it => it === true);
	}
	isAddit() {
		return Object.values(this.compound?.additives).find(it => it === true);
	}
	isFeatures() {
		return Object.values(this.compound?.features).find(it => it === true);
	}
}
